<template>
    <zw-sidebar @shown="shown" :title="$t('export.title.export')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <b-form-group>
                            <template v-slot:label>
                                {{ $t('export.label.header_line') }}
                            </template>
                            <b-input-group>
                                <b-form-checkbox
                                    v-model="form.header_line"
                                ></b-form-checkbox>
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <zw-select-group v-model="form.source"
                                         :options="sourceOptions"
                                         name="source"
                                         label-prefix="export.label."
                                         validate="required"
                        ></zw-select-group>
                    </b-col>

                    <b-col cols="12">
                        <zw-select-group v-model="form.format"
                                         :options="formatOptions"
                                         name="format"
                                         label-prefix="export.label."
                                         validate="required"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-select-group v-model="form.sftp_profile"
                                         :options="getSftpProfiles()"
                                         name="sftp_profile"
                                         label-prefix="export.label."
                        ></zw-select-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button block @click="doExport" variant="primary">
                            {{ $t('common.button.export') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import commonSave from "@/bundles/erika_common_bundle/mixins/common-save";

const defaultForm = {
    header_line: true,
    source: 'selected',
    format: 'csv',
}
export default {
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            form: null,
        }
    },
    mixins: [commonSave],
    methods: {
        ...mapGetters('Export', ['getSources', 'getFormats', 'getSftpProfiles']),
        shown() {
            const exportOptions = this.$store.dispatch('Export/fetchOptions')

            Promise.all([exportOptions]).then((values) => {
                this.form = JSON.parse(JSON.stringify(defaultForm))
                this.form.type = this.payload.type

                if (!this.payload.selected.length) {
                    this.form.source = 'all'
                }

                this.loading = false
            })
        },
        doExport() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let form = JSON.parse(JSON.stringify(this.form))
                    form.ids = this.payload.selected.map(row => row.id)

                    this.$store.dispatch('Export/doExport', {
                        type: this.payload.type,
                        tableFormat: this.payload.tableFormat,
                        filter: this.payload.filter,
                        currentPage: this.payload.tableOptions.currentPage,
                        perPage: this.payload.tableOptions.perPage,
                        additionalOptions: this.payload.additionalOptions,
                        export: form
                    })
                        .then((response) => {
                            this.commonAfterSave(response)
                        }, () => {
                            this.loading = false
                        })
                } else {
                    this.loading = false
                }
            })
        }
    },
    computed: {
        sourceOptions: function () {
            let options = []
            this.getSources().forEach(key => {
                let disabled = false
                if (key == 'selected') {
                    disabled = !this.payload.selected.length
                }
                options.push({
                    value: key,
                    text: this.$t('export.source.' + key),
                    disabled: disabled
                })
            })

            return options
        },
        formatOptions: function () {
            let options = []
            this.getFormats().forEach(key => {
                options.push({
                    value: key,
                    text: this.$t('export.format.' + key),
                })
            })

            return options
        },
    },
}
</script>